import base64 from 'base-64'

export function getFrontVariantId(id: number): string {
  //return base64.encode(`gid://shopify/ProductVariant/${id}`)
  return `gid://shopify/ProductVariant/${id}`
}

export function getVariantId(id: string): string {
  const decodeArr = id.split('/') //base64.decode(id).split('/')

  return decodeArr[decodeArr.length - 1]
}
