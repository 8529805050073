import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import debounce from 'lodash.debounce'
import * as Sentry from '@sentry/nextjs'
import { DebouncedFunc } from 'lodash'

import {
  cashbackPercent,
  shopifyClient,
  shopifyCartCookie,
  path,
  trialMembershipSku,
  annualmembershipSku,
  VITALIVE_SERVER_URL,
} from '../constants/constants'
import { CartContent, ILineItem } from '../interfaces/CartContent'
import { LineItem } from '../interfaces/ShopifyInterface'
import { createCheckout } from '../utilities/shopifyUtilites'
import { parseJson } from '../utilities/jsonUtils'
import { useRouter } from 'next/router'
import axios from 'axios'
import { getFrontVariantId, getVariantId } from '../utilities/getFrontVariantId'
import {
  getDiscountPrice,
  getLineItemsWithNewPrice,
} from '../utilities/getDiscountPrice'

// explanation of variables

let debounceFunc: DebouncedFunc<() => void> // our debounced function
let _checkoutLineItem: ILineItem // checkoutLineItem from cartContent
let _currentVariantId: string // variant Id, using for check is same item clicked or not
let _isThereDebounce: boolean // is there debounced fucntion or not

export const useCart = (customerEmail: string = '') => {
  const [cookies, setCookie, removeCookie] = useCookies([shopifyCartCookie])
  const [cartContent, setCartContent] = useState<CartContent>({})
  const [productsTotalQuantity, setProductsTotalQuantity] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [coefficientDiscount, setCoefficientDiscount] = useState(1)
  const [discountFromCode, setDiscountFromCode] = useState(0)
  const [cashbackAmount, setCashbackAmount] = useState(0)
  const [reconciliation, setReconciliation] = useState(false)
  const router = useRouter()
  const { draftOrderId, checkoutId, skus } = router.query

  const fetchShopifyCart = async (_coefficientDiscount: number) => {
    setIsLoading(true)

    if (checkoutId || draftOrderId || skus || cookies.shopifyCart?.checkoutId) {
      try {
        let cartInfo
        const cartCheckoutLineItems: CartContent = {}
        let countOfProducts = 0
        let newCheckout
        if (skus) {
          let products = await axios
            .get(
              `${VITALIVE_SERVER_URL || ''}/product?status=active&sku=${skus}`
            )
            .then((res) => res.data.result)

          const lineItems = products.map((item: any) => ({
            variantId: item?.frontVariantId,
            quantity: 1,
          }))

          newCheckout = await shopifyClient.checkout.create({
            email: customerEmail || undefined,
            lineItems: lineItems,
          } as any)

          newCheckout = parseJson<any>(JSON.stringify(newCheckout))

          newCheckout.lineItems.forEach((lineItem: LineItem) => {
            if (lineItem?.variant?.sku !== trialMembershipSku) {
              countOfProducts += lineItem?.quantity || 0
            }
            cartCheckoutLineItems[lineItem?.variant?.id!] = {
              checkoutLineItemId: lineItem?.id || '',
              quantity: lineItem?.quantity || 0,
              itemPrice: lineItem?.variant?.price?.amount
                ? (
                    +lineItem.variant.price.amount *
                    (_coefficientDiscount || 1) *
                    (1 - discountFromCode)
                  ).toFixed(2)
                : '0',
              variantId: getVariantId(lineItem?.variant?.id!) || '',
              isMembership:
                lineItem?.variant?.sku?.trim() === trialMembershipSku ||
                lineItem?.variant?.sku?.trim() === annualmembershipSku,
            }
          })

          // checking if there is a checkoutId in the query or if there is nothing at all
        } else if (
          (checkoutId || cookies.shopifyCart?.checkoutId) &&
          !draftOrderId
        ) {
          cartInfo = await shopifyClient.checkout.fetch(
            (checkoutId as string) || cookies.shopifyCart?.checkoutId
          )
          ;(cartInfo?.lineItems as LineItem[])?.forEach(
            (lineItem: LineItem) => {
              if (
                lineItem?.variant?.sku !== trialMembershipSku &&
                lineItem?.variant?.sku !== annualmembershipSku
              ) {
                countOfProducts += lineItem?.quantity || 0
              }
              cartCheckoutLineItems[lineItem?.variant?.id!] = {
                checkoutLineItemId: lineItem?.id || '',
                quantity: lineItem?.quantity || 0,
                itemPrice: lineItem?.variant?.price?.amount
                  ? (
                      +lineItem.variant.price.amount *
                      _coefficientDiscount *
                      (1 - discountFromCode)
                    ).toFixed(2)
                  : '0',
                variantId: getVariantId(lineItem?.variant?.id!) || '',
                isMembership:
                  lineItem?.variant?.sku?.trim() === trialMembershipSku ||
                  lineItem?.variant?.sku?.trim() === annualmembershipSku,
              }
            }
          )
          // recovery checkout from draftOrderId from query params
        } else if (draftOrderId) {
          cartInfo = await axios
            .get(
              `${VITALIVE_SERVER_URL}/checkout/order?draftOrderId=${draftOrderId}`
            )
            .then((res) => res.data)

          const lineItems = cartInfo.line_items.map((item: any) => ({
            variantId: getFrontVariantId(item?.variant_id),
            quantity: item?.quantity,
          }))

          newCheckout = await shopifyClient.checkout.create({
            email: customerEmail || undefined,
            lineItems: lineItems,
          } as any)
          newCheckout = parseJson<any>(JSON.stringify(newCheckout))

          newCheckout.lineItems.forEach((lineItem: LineItem) => {
            if (
              lineItem?.variant?.sku !== trialMembershipSku &&
              lineItem?.variant?.sku !== annualmembershipSku
            ) {
              countOfProducts += lineItem?.quantity || 0
            }
            cartCheckoutLineItems[lineItem?.variant?.id!] = {
              checkoutLineItemId: lineItem?.id || '',
              quantity: lineItem?.quantity || 0,
              itemPrice: lineItem?.variant?.price?.amount
                ? (
                    +lineItem.variant.price * (_coefficientDiscount || 1)
                  ).toFixed(2)
                : '0',
              variantId: getVariantId(lineItem?.variant?.id!) || '',
              isMembership:
                lineItem?.variant?.sku?.trim() === trialMembershipSku ||
                lineItem?.variant?.sku?.trim() === annualmembershipSku,
            }
          })
        }

        setCookie(
          shopifyCartCookie,
          {
            // ...cookies.shopifyCart,
            checkoutId: newCheckout?.id
              ? newCheckout?.id
              : checkoutId || cookies.shopifyCart?.checkoutId,
            countOfProducts: countOfProducts ?? 0,
            checkoutLineItems: cartCheckoutLineItems,
          },
          path
        )

        setCartContent(cartCheckoutLineItems)
      } catch (err) {
        Sentry.captureException(err)
        console.error('fetchShopifyCart: ', err)
        clearCart()
      } finally {
        setIsLoading(false)
      }
    } else {
      createCheckout(customerEmail, setCookie).finally(() =>
        setIsLoading(false)
      )
    }
  }

  const setterCoefficientDiscount = async () => {
    const coefficientDiscount = await getDiscountPrice()
    setCoefficientDiscount(coefficientDiscount)
    fetchShopifyCart(coefficientDiscount)
  }

  useEffect(() => {
    setterCoefficientDiscount()
  }, [coefficientDiscount])

  useEffect(() => {
    const keys = Object.keys(cartContent)
    if (!isLoading || keys.length) {
      let countOfProducts = 0
      keys.forEach((key) => {
        if (
          cartContent[key] && cartContent[key]?.isMembership
            ? +cartContent[key]?.itemPrice > 0
            : cartContent[key] && !cartContent[key]?.isMembership
        ) {
          countOfProducts += cartContent[key]?.quantity
        }
      })
      updateTotalPrice()
      setCookie(
        shopifyCartCookie,
        {
          ...cookies.shopifyCart,
          countOfProducts,
          checkoutLineItems: {
            ...cartContent,
          },
        },
        path
      )
    }
  }, [cartContent])

  const updateTotalPrice = () => {
    if (cartContent) {
      let totalQuantity = 0

      const _price = Object.keys(cartContent)
        .reduce((acc, itemKey) => {
          if (cartContent[itemKey]) {
            totalQuantity += cartContent[itemKey]?.quantity || 0
            acc +=
              cartContent[itemKey]?.quantity *
                parseFloat(cartContent[itemKey]?.itemPrice) || 0
          }
          return acc
        }, 0)
        .toFixed(2)
      setGrandTotal(parseFloat(_price))
      setProductsTotalQuantity(totalQuantity)

      if (parseFloat(_price) > 20) {
        const cashback = (parseFloat(_price) / 100) * cashbackPercent
        setCashbackAmount(+cashback.toFixed(2))
      } else {
        setCashbackAmount(0)
      }
    }
  }

  const saveInCookie = (
    foundedLineItem?: LineItem,
    frontVariantId?: string,
    variantId?: string
  ) => {
    setCartContent((_prev) => ({
      ..._prev,
      [frontVariantId || '']: {
        ..._prev[frontVariantId || ''],
        quantity: foundedLineItem?.quantity || 0,
        checkoutLineItemId: foundedLineItem?.id || '',
        itemPrice: foundedLineItem?.variant?.price?.amount
          ? foundedLineItem?.variant?.price?.amount
          : '0',
        variantId,
      },
    }))
  }

  const updateLineItem = async (
    frontVariantid: string,
    quantity: number,
    variantId: string
  ) => {
    setReconciliation(true)

    try {
      if (!cookies?.shopifyCart?.checkoutId) {
        await createCheckout(customerEmail, setCookie)
      }

      let res = await shopifyClient.checkout.updateLineItems(
        cookies?.shopifyCart?.checkoutId,
        [
          {
            id: cookies.shopifyCart.checkoutLineItems[frontVariantid]
              .checkoutLineItemId
              ? cookies.shopifyCart.checkoutLineItems[frontVariantid]
                  .checkoutLineItemId
              : '',
            quantity,
          },
        ]
      )
      res = parseJson(JSON.stringify(res)) as any
      let foundedLineItem = (res?.lineItems as LineItem[]).find(
        (el: LineItem) => el?.variant?.id === frontVariantid
      )

      foundedLineItem = (await getLineItemsWithNewPrice(
        foundedLineItem!,
        coefficientDiscount
      )) as LineItem

      foundedLineItem = (await getLineItemsWithNewPrice(
        foundedLineItem!,
        1 - discountFromCode
      )) as LineItem

      saveInCookie(foundedLineItem, frontVariantid, variantId)
    } catch (err) {
      Sentry.captureException(err)
      console.error('updateLineItem: ', err)
    } finally {
      _isThereDebounce = false
      setReconciliation(false)
    }
    setReconciliation(false)
  }

  const removeLineItems = async (lineItemIdsToRemove: string) => {
    setReconciliation(true)

    try {
      if (!cookies?.shopifyCart?.checkoutId) {
        await createCheckout(customerEmail, setCookie)
      }
      await shopifyClient.checkout.removeLineItems(
        cookies?.shopifyCart?.checkoutId,
        [cartContent[lineItemIdsToRemove].checkoutLineItemId]
      )
      // res = JSON.parse(JSON.stringify(res))
    } catch (err) {
      Sentry.captureException(err)
      console.error('removeLineItems: ', err)
    } finally {
      _isThereDebounce = false
      setReconciliation(false)
    }
  }

  const addLineItems = async (
    frontVariantId: string,
    variantId: string,
    quantity: number
  ) => {
    setReconciliation(true)
    try {
      if (!cookies?.shopifyCart?.checkoutId) {
        await createCheckout(customerEmail, setCookie)
      }
      let res = await shopifyClient.checkout.addLineItems(
        cookies?.shopifyCart?.checkoutId,
        [
          {
            variantId: frontVariantId,
            quantity,
          },
        ]
      )
      console.log('addLineItems')

      res = parseJson(JSON.stringify(res)) as any
      console.log(res)
      console.log('frontVariantId=' + frontVariantId)
      let foundedLineItem = (res.lineItems as LineItem[]).find(
        (el: LineItem) => el?.variant?.id === frontVariantId
      )

      foundedLineItem = (await getLineItemsWithNewPrice(
        foundedLineItem!,
        coefficientDiscount
      )) as LineItem

      if (coefficientDiscount !== 1) {
        foundedLineItem = (await getLineItemsWithNewPrice(
          foundedLineItem!,
          1 - discountFromCode
        )) as LineItem
      }

      saveInCookie(foundedLineItem, frontVariantId, variantId)
    } catch (err) {
      Sentry.captureException(err)
      console.error('addLineItems: ', err)
    } finally {
      _isThereDebounce = false
      setReconciliation(false)
    }
  }

  // using to setITem to shopify
  //

  const setShopifyCart = (frontVariantId: string, variantId: string) => {
    const updateShopifyCart = async (
      type: 'add' | 'remove' | 'update',
      quantity?: number
    ) => {
      let quantityToSend: number
      // take or add new checkoutLineItem
      if (cartContent[frontVariantId]) {
        _checkoutLineItem = cartContent[frontVariantId]
      } else {
        _checkoutLineItem = {
          variantId: '',
          quantity: 0,
          checkoutLineItemId: '',
          itemPrice: '',
        }
      }

      if (quantity !== undefined) {
        quantityToSend = quantity
      } else {
        // using type checking to take quantitof of products to send backend
        if (type === 'add') {
          quantityToSend = _checkoutLineItem.quantity
            ? ++_checkoutLineItem.quantity
            : 1
        } else {
          quantityToSend =
            _checkoutLineItem.quantity > 1 ? --_checkoutLineItem.quantity : 0
        }
      }

      // case 1: if user clicked on same product
      // case 2: clicked on another product
      if (_currentVariantId === frontVariantId) {
        // if we have debounced func
        if (_isThereDebounce) {
          debounceFunc?.cancel()
        }
        saveInCookie(
          {
            id: _checkoutLineItem ? _checkoutLineItem.checkoutLineItemId : '',
            quantity: quantityToSend,
            variant: {
              id: _checkoutLineItem?.variantId ?? '',
              price: _checkoutLineItem?.itemPrice ?? '',
            },
          },
          frontVariantId,
          variantId
        )

        // debounced func creater
        addDebounce(frontVariantId, variantId, quantityToSend, type)
      } else {
        _currentVariantId = frontVariantId
        if (_isThereDebounce) {
          debounceFunc()
          debounceFunc.flush()
        }

        saveInCookie(
          {
            id: _checkoutLineItem ? _checkoutLineItem.checkoutLineItemId : '',
            quantity: quantityToSend,
            variant: {
              id: _checkoutLineItem?.variantId ?? '',

              price: _checkoutLineItem?.itemPrice ?? '',
            },
          },
          frontVariantId,
          variantId
        )
        addDebounce(frontVariantId, variantId, quantityToSend, type)
      }
    }
    return updateShopifyCart
  }

  //helpers
  const debounceFunctionHandler = (callback: () => void) => {
    const debounceHandler = debounce(() => callback(), 0)
    return debounceHandler
  }

  const addDebounce = (
    frontVariantId: string,
    variantId: string,
    quantityToSend: number,
    type: string
  ) => {
    _isThereDebounce = true
    // depends on type, we adding or substracting items
    if (type === 'add') {
      // do we have  this item in shopycart
      if (!_checkoutLineItem.checkoutLineItemId) {
        debounceFunc = debounceFunctionHandler(() =>
          addLineItems(frontVariantId, variantId, quantityToSend)
        )
      } else {
        debounceFunc = debounceFunctionHandler(() =>
          updateLineItem(frontVariantId, quantityToSend, variantId)
        )
      }
    } else {
      //same but for substract or delete
      if (quantityToSend) {
        debounceFunc = debounceFunctionHandler(() =>
          updateLineItem(frontVariantId, quantityToSend, variantId)
        )
      } else {
        removeProduct(frontVariantId, true)
      }
    }
    quantityToSend && debounceFunc()
  }

  const clearCart = () => {
    removeCookie(shopifyCartCookie, path)
  }

  const removeProduct = (
    frontVariantId: string,
    isDebounced: boolean = false
  ) => {
    let isExist = false

    if (cartContent[frontVariantId]?.checkoutLineItemId) {
      isExist = true
    }

    const withoutDeleteElement = Object.keys(cartContent).reduce(
      (acc, key) =>
        key === frontVariantId ? acc : { ...acc, [key]: cartContent[key] },
      {}
    )
    setCartContent(withoutDeleteElement)

    if (isExist) {
      if (isDebounced) {
        debounceFunc = debounceFunctionHandler(() =>
          removeLineItems(frontVariantId)
        )
        debounceFunc()
      } else {
        removeLineItems(frontVariantId)
      }
    }
  }

  return {
    cartContent,
    setCartContent,
    productsTotalQuantity,
    grandTotal,
    cashbackAmount,
    setShopifyCart,
    removeProduct,
    isLoading,
    reconciliation,
    setDiscountFromCode,
  }
}
